import type { MaskedCard } from '@mc-alberta/types'
import SRCError from '../SRCError'
import { DEFAULT_PROGRAM_ID, MPT_PROGRAM_ID } from '../constants'
import decorateExceptionsWithErrorDetail from '../helpers/decorateExceptionsWithErrorDetail'
import nudetectWidgetData from '../helpers/extractNudetectWidgetData'
import { cacheCardInSrcProfile } from '../helpers/profile'
import recursiveEnroll from '../recursiveEnroll'
import transform from '../transform'
import MethodContext from '../types/MethodContext'
import utils from '../utils'
import validate from '../validate'

export default decorateExceptionsWithErrorDetail(async function enrollCard(
  this: MethodContext,
  {
    profileOptOut,
    encryptedCard,
    srciTransactionId
  }: {
    encryptedCard: string
    srciTransactionId?: string
    profileOptOut?: boolean // non-EMVCo and not exposed to merchant
  }
) {
  const { state } = this

  utils.performNonBlockingValidations(
    {
      params: { encryptedCard, srciTransactionId },
      methodName: 'enrollCard'
    },
    state
  )
  if (!encryptedCard) {
    throw new SRCError({ error: { status: 400, reason: 'CARD_MISSING', message: 'Card missing' } })
  }

  if (srciTransactionId) {
    state.headers = {
      ...state.headers,
      'SRCI-Transaction-Id': srciTransactionId
    }
  }

  const data = profileOptOut
    ? {
        encryptedCard,
        programId: MPT_PROGRAM_ID,
        dsaTransactionOptions: transform.dpaTransactionOptions.request({
          payloadTypeIndicatorCheckout: null!,
          ...state.dpaTransactionOptions
        })
      }
    : {
        encryptedCard,
        programId: DEFAULT_PROGRAM_ID
      }

  const message = {
    method: 'post',
    url: 'cards',
    headers: {
      ...state.headers,
      'aker-request-retriable': false // Not in spec, prevents aker from re-issuing card due to synapse timeout
    },
    data,
    // eslint-disable-next-line prefer-rest-params
    ...nudetectWidgetData(state.isMastercardSrci, arguments)
  }

  const result = await recursiveEnroll<{
    maskedCard: MaskedCard
    authorization?: string
  }>(message, state)
  validate.response(result, { 400: 'INVALID_PARAMETER' })
  // TODO: handle error reponse
  // CARD_ADD_FAILED
  // CARD_SECURITY_CODE_MISSING
  // CARD_INVALID
  // CARD_EXP_INVALID =
  //  code:400
  //  status:INVALID_ARGUMENT
  //  details[0] source:enrollRequest.card.month||year

  // for 'new users', we create an 'anonymous' jwt for later profile bind in DCF
  if (result.authorization) {
    // aka cardToken
    state.cardToken = result.authorization
  }

  const response = {
    maskedCard: transform.card.response(result.maskedCard), // required
    srcCorrelationId: state.headers['SRC-Correlation-Id'] // optional
  }

  // cache masked cards so we can find them by id in checkout()
  cacheCardInSrcProfile.call(this, {
    maskedCard: response.maskedCard
  })

  return response
}, 'CARD_ENROLLMENT')
